export const WEAPON_DETAIL_PAGE = 'WeaponDetailPage';
export const ARMED_FORCES_PAGE = 'ArmedForcesPage';
export const MILITANT_ORGANIZATION_PAGE = 'MilitaryOrganizationPage';
export const NEWS_PAGE = 'NewsPage';
export const REPORT_PAGE = 'ReportPage';

export const DETAILED_PAGES = [
  WEAPON_DETAIL_PAGE,
  ARMED_FORCES_PAGE,
  MILITANT_ORGANIZATION_PAGE,
  NEWS_PAGE,
  REPORT_PAGE,
];
