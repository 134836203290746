import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import { getPageSearch } from '../../api/wagtail';
import CategoryName from '../../components/CategoryName/CategoryName';
import { useGlobalContext } from '../../components/GlobalContextProvider/GlobalContextProvider';
import SearchList from '../../components/SearchList';
import SearchListWithPagesPagination from '../../components/SearchList/SearchListWithPagesPagination';
import { backendDateFormat } from '../../consts/date-formats';
import { ACTIVATE_SEARCH_EVENT } from '../../consts/events';
import eventBus from '../../lib/eventBus';
import { CategoryNav } from '../../shared-styled/layout/CategoryContainer';
import { matchUrl } from '../../utils/matchUrl';
import NewsAndReportSearchPage from './NewsAndReportSearchPage';
import SearchPageContainer from './SearchPage.styled';

const SearchPage = () => {
  const {
    searchValue,
    searchContentType,
    searchContentName,
    dateRangeState,
    subcategoryFilterValue,
  } = useGlobalContext();

  const router = useRouter();
  const stateRef = useRef();
  stateRef.current = [
    searchValue,
    searchContentType,
    dateRangeState,
    subcategoryFilterValue,
  ];

  const [searchResult, setSearchResult] = useState(null);
  const [lastSearchValue, setLastSearchValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isScrolledDown, setIsScrollDown] = useState(false);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const [isOnScrollPagination, setIsOnScrollPagination] = useState(true);

  const isNewsOrReport = useMemo(
    () => matchUrl(searchContentType || '', ['reportpage', 'newspage']),
    [searchContentType],
  );

  const doSearch = async () => {
    const [
      searchValue,
      searchContentType,
      dateRangeState,
      subcategoryFilterValue,
    ] = stateRef.current;

    setIsLoading(true);
    if (offset < 1) {
      setSearchResult([]);
    }
    setLastSearchValue(searchValue);

    try {
      const res = await getPageSearch(searchValue, {
        contentType: searchContentType,
        fromDate: dateRangeState[0].startDate
          ? dayjs(dateRangeState[0].startDate).format(backendDateFormat)
          : null,
        toDate: dateRangeState[0].startDate
          ? dayjs(dateRangeState[0].endDate).format(backendDateFormat)
          : null,
        subCategory: subcategoryFilterValue?.value,
        offset: offset || 0,
      });

      if (offset > 1 && (isNewsOrReport || isOnScrollPagination)) {
        setSearchResult((searchResult) => {
          if (searchResult && res.json.items) {
            return [...searchResult, ...res.json.items];
          }
          return res.json.items;
        });
      } else {
        setSearchResult(res.json.items);
        setTotalItems(
          searchContentType
            ? res.json.meta[searchContentType]
            : res.json.meta.all,
        );
      }
      setIsScrollDown(false);
    } catch {
      toast.error('Server error');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const removeListener = eventBus.addEventListener(
      ACTIVATE_SEARCH_EVENT,
      doSearch,
    );
    return () => removeListener();
  }, []);

  useEffect(() => {
    if (!searchValue || (!searchContentType && subcategoryFilterValue)) {
      return;
    }

    doSearch();
  }, [offset]);

  useEffect(() => {
    if (router.query.search2) {
      setIsOnScrollPagination(false);
    }

    if (!searchValue || (!searchContentType && subcategoryFilterValue)) {
      return;
    }

    eventBus.emit(ACTIVATE_SEARCH_EVENT);
  }, [router.query]);

  const Component = isOnScrollPagination
    ? SearchList
    : SearchListWithPagesPagination;

  return isNewsOrReport ? (
    <NewsAndReportSearchPage
      items={searchResult}
      isLoading={isLoading}
      setOffset={setOffset}
      isScrolledDown={isScrolledDown}
      setIsScrollDown={setIsScrollDown}
    />
  ) : (
    <SearchPageContainer>
      <CategoryNav>
        <CategoryName>{searchContentName || 'Search'}</CategoryName>
      </CategoryNav>

      <Component
        items={searchResult}
        isLoading={isLoading}
        lastSearchValue={lastSearchValue}
        offset={offset}
        setOffset={setOffset}
        isScrolledDown={isScrolledDown}
        setIsScrollDown={setIsScrollDown}
        totalItems={totalItems}
      />
    </SearchPageContainer>
  );
};

export default SearchPage;
