import styled from '@emotion/styled';
import { css, Flex } from 'theme-ui';
import pxToRem from '../../utils/pixelsToRem';

import RawHtml from '../RawHtml';

export const Title = styled.h2(
  css({
    fontWeight: '600',
    fontSize: '1.5rem',
    marginTop: '2rem',
    marginBottom: '1rem',
  }),
);

export const StyledLink = styled.a(
  css({
    color: 'greenDark',
    textDecoration: 'none',
    cursor: 'pointer',

    '&:hover': {
      color: 'hoverLink',
    },
  }),
);

export const Footer = styled(Flex)`
  justify-content: space-between;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.08em;
`;

export const StyledRawHtml = styled(RawHtml)`
  margin-bottom: 1.2rem;
  line-height: ${pxToRem(28)};
`;
