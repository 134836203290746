import { css } from '@emotion/react';
import styled from '../../lib/transient-styled';

const StyledRawHtml = styled.div(
  css({
    lineHeight: '1.2rem',
    overflow: 'hidden',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',

    table: {
      width: 'auto',
    },

    pre: {
      overflowX: 'auto',
    },

    blockquote: {
      borderInlineStart: '0.25em solid rgb(145, 145, 145)',
      margin: ['1em 0', '0'],
      padding: '1em 2em',
      color: '#5C5C5C',
    },
  }),
  ({ $linesLimit }) =>
    css({
      WebkitLineClamp: `${$linesLimit}`,
    }),
);

export default StyledRawHtml;
